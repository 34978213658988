<template>
  <v-card min-height="550" :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''" color="#C3FFF5" flat>
    <v-row class="justify-md-end justify-space-between mx-6 pt-4">
      <v-icon v-if="$vuetify.breakpoint.smAndDown" class="primary--text" large
        @click="$router.go(-1)">mdi-arrow-left-drop-circle-outline</v-icon>
      <lang-swicther />
    </v-row>
    <v-card-text>
      <v-row>
        <v-col class="text-center">
          <h2 class="text-h6">{{ $t("signUp.title") }}</h2>
        </v-col>
      </v-row>
      <v-card max-width="800" elevation="0" class="transparent  mx-auto">

        <ValidationObserver ref="observer" v-slot="{ valid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" class="mt-6">
            <v-row>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.firstName')" rules="required">
                  <v-text-field class="custom-field1 mx-auto " autofocus v-model="firstName"
                    :placeholder="$t('signUp.firstName')" required outlined :error-messages="errors" rounded
                    dense></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.lastName')" rules="required">
                  <v-text-field class="custom-field1 mx-auto " v-model="lastName" :placeholder="$t('signUp.lastName')"
                    required outlined :error-messages="errors" rounded dense></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dob"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dob" outlined dense rounded class="custom-field1 mx-auto  "
                      :placeholder="$t('signUp.dob')" append-icon="mdi-calendar" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dob" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dob)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider :name="$t('signUp.email')" v-slot="{ errors }" rules="required|email">
                  <v-text-field class="custom-field1 mx-auto " v-model="email" :placeholder="$t('signUp.email')"
                    required outlined :error-messages="errors" rounded dense></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="password" ref="password" rules="required|max:20|min:6">
                  <v-text-field v-model="password" :error-messages="errors" placeholder="Password" rounded outlined
                    dense class="custom-field1 mx-auto " :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'" @click:append="show = !show" required></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="confirm password" rules="required|confirmed:password">

                  <v-text-field v-model="confirmPassword" class="custom-field1 mx-auto " rounded outlined
                    :error-messages="errors" dense placeholder="Confirm Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show" required></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.area')" rules="required">
                  <v-autocomplete v-model="area" :items="areaOptions" :loading="isLoading"
                    :search-input.sync="searchArea" chips clearable dense outlined rounded solo flat type="number"
                    :error-messages="errors" class=" custom-field1 mx-auto" hide-selected item-text="nameEn"
                    item-value="_id" :label="$t('signUp.area')">
                    <template v-slot:no-data>
                      <v-list-item dense>
                        <v-list-item-title>
                          {{ "Search area" }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar color="indigo" class="text-h6 font-weight-light white--text">
                        {{ item.nameEn.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.nameEn"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.nameAr"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.block')" rules="required">
                  <v-autocomplete v-model="block" :items="blockOptions" chips clearable outlined
                    :error-messages="errors" rounded solo flat type="number" dense class=" custom-field1 mx-auto"
                    hide-selected item-text="block" item-value="_id" :label="$t('signUp.block')">
                    <template v-slot:no-data>
                      <v-list-item dense>
                        <v-list-item-title>
                          {{ "No data" }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                        {{ item.block.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.block"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.street')" rules="required">
                  <v-text-field class="custom-field1 mx-auto " v-model="street" :placeholder="$t('signUp.street')"
                    required outlined :error-messages="errors" rounded dense></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('signUp.jadha')">
                  <v-text-field class="custom-field1 mx-auto " v-model="jadha" :placeholder="$t('signUp.jadha')"
                    required outlined :error-messages="errors" rounded dense></v-text-field>

                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('signUp.floor')">
                  <v-text-field class=" custom-field1 mx-auto " v-model="floor" :placeholder="$t('signUp.floor')"
                    required outlined :error-messages="errors" rounded dense></v-text-field>

                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" :name="$t('signUp.flatOrDoor')" rules="required">
                  <v-text-field :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''" v-model="flatOrDoor"
                    class=" custom-field1 mx-auto" :placeholder="$t('signUp.flatOrDoor')" required 
                    outlined :error-messages="errors" rounded dense></v-text-field>

                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="House/Building Number" rules="required">
                  <v-text-field class=" custom-field1 mx-auto" v-model="buildingNumber"
                    :placeholder="`House/Building Number`" required outlined :error-messages="errors" rounded
                    dense></v-text-field>


                </ValidationProvider>
              </v-col>
              <v-col></v-col>
              <v-col cols="12" sm="12" md="6" class=" d-flex ps-md-14 py-0 align-center">
                <v-switch v-model="defaultAddress" :label="$t('signUp.defaultAddress')" color="primary"
                  class="mt-0 mx-auto" hide-details></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="d-flex justify-center">
                <v-radio-group v-model="addressType" row mandatory>
                  <v-radio :label="$t('signUp.home')" class="my-2" value="Home"></v-radio>
                  <v-radio class="my-2" :label="$t('signUp.office')" value="Office"></v-radio>
                  <v-radio class="my-2" :label="$t('signUp.others')" value="Others"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn depressed rounded color="primary custom-font" class="black--text font-weight-light mt-4 mb-6"
                  :width="$vuetify.breakpoint.smAndDown ? 250 : 315" type="submit" :disabled="!valid"
                  :loading="btn_loading">{{ $t("signUp.btnTitle") }}
                  <v-icon right> mdi-arrow-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-layout justify-center> </v-layout>
          </form>
        </ValidationObserver>

      </v-card>
      <p v-if="errorFromApi" class="red--text text-center mt-6">
        {{ errorFromApi }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { customerSignUp } from "../../methods/signUp";
import { updateCustomer } from "../../../health/methods/updateCustomer";
import "@/core/methods/validation_rules";
export default {
  data() {
    return {
      dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      btn_loading: false,
      searchArea: "",
      errorFromApi: null,
      show: false,
      show1: false,
      password: "",
      confirmPassword: "",
      gender: "male",
      firstName: "",
      lastName: "",
      email: "",
      area: "",
      block: "",
      street: "",
      jadha: "",
      floor: "",
      flatOrDoor: "",
      buildingNumber: "",
      defaultAddress: true,
      addressType: null,
      customer: {},
      areaOptions: [],
      isLoading: false,
      blockOptions: [],
    };
  },
  components: {
    "lang-swicther": () => import("@/core/components/LangSwitch"),
  },
  watch: {
    area() {
      this.$apollo
        .query({
          query: require("@/user/api/queries/get_block_by_area.graphql"),
          variables: {
            id: this.area,
          },
        })
        .then((data) => {
          this.blockOptions = data.data.getBlocksByArea.blocks;
        });
    },
    searchArea(val) {
      this.isLoading = true;
      this.$apollo
        .query({
          query: require("@/user/api/queries/get_area_query.graphql"),
          variables: {
            nameEn: "",
            nameAr: "",
            searchKey: val,
          },
        })
        .then((data) => {
          this.areaOptions = data.data.getAllAreas.areas;
          this.isLoading = false;
        });
    },
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      this.btn_loading = true;
      const input = {
        firstnameEn: this.firstName,
        lastnameEn: this.lastName,
        email: this.email,
        dob: this.dob,
        phone: sessionStorage.getItem("USER_PHONE") || "",
        password: this.password,
      };
      const deliveryInput = {
        area: this.area,
        block: this.block,
        street: this.street,
        jedha: this.jadha,
        addressType: this.addressType,
        is_default: this.defaultAddress,
        building_number: this.buildingNumber,
      };
      customerSignUp({ input: input }).then((response) => {
        this.btn_loading = false;
        if (response.graphQLErrors) {
          this.errorFromApi = response.graphQLErrors[0].message;
          setTimeout(() => {
            this.errorFromApi = null;
          }, 5000);
          return
        }
        this.customer = response.data.customerSignUp;

        localStorage.setItem("Customer", JSON.stringify(this.customer));
        localStorage.setItem("apollo-done-token", this.customer.token);
        updateCustomer({
          customerId: this.customer.customer._id,
          userId: this.customer.user._id,
          deliveryInput: deliveryInput,
        }).then((res) => {
          if (res.data.updateCustomer) {
            this.areaOptions.map(area => {
              debugger
              if (area._id === deliveryInput.area) {
                deliveryInput.area = area.nameEn
              }
            })

            this.blockOptions.map(block => {
              if (block._id === deliveryInput.block) {
                deliveryInput.block = block.block
              }
            })
            localStorage.setItem("deliveryAddress", JSON.stringify(deliveryInput));
            this.$router.push("/health");
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.custom-small-width {
  max-width: 130px !important;
}

.custom-xs-width {
  max-width: 120px !important;
}

.custom-field1 {
  max-width: 315px !important;
}

.custom-field1>>>.v-input__slot {
  background-color: white !important;
}
</style>
